import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				O nas | O Akademii Crenzol
			</title>
			<meta name={"description"} content={"Wzmacnianie pozycji kierowców, nawigacja przyszłości - odkryj sposób Crenzol"} />
			<meta property={"og:title"} content={"O nas | O Akademii Crenzol"} />
			<meta property={"og:description"} content={"Wzmacnianie pozycji kierowców, nawigacja przyszłości - odkryj sposób Crenzol"} />
			<meta property={"og:image"} content={"https://crenzol.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://crenzol.com/img/6819089.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://crenzol.com/img/6819089.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://crenzol.com/img/6819089.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://crenzol.com/img/6819089.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://crenzol.com/img/6819089.png"} />
			<meta name={"msapplication-TileImage"} content={"https://crenzol.com/img/6819089.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://crenzol.com/img/3.jpg"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="40px 0px 0px 50px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="--primary" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						O nas
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
						Nasz etos w Akademii Crenzol
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						W Akademii Crenzol nasza misja wykracza poza nauczanie jazdy. Jesteśmy tutaj, aby zaszczepiać pewność siebie, przekazywać nawyki bezpiecznej jazdy i zapewniać, że nasi uczniowie są gotowi do drogi, zarówno pod względem umiejętności, jak i sposobu myślenia. Nasza akademia to połączenie tradycji i innowacji, gdzie nauka jest równie przyjemna, co skuteczna.

					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						margin="30px 0px 0px 0px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-template-rows="repeat(2, 1fr)"
						lg-margin="50px 0px 0px 0px"
						md-margin="40px 0px 0px 0px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box min-width="100px" min-height="80px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Koncentracja na bezpiecznej i pewnej jeździe
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Wierzymy, że pewny siebie kierowca to bezpieczny kierowca. Nasze kursy opierają się na tej filozofii, zapewniając, że każdy absolwent Akademii Crenzol jest nie tylko wykwalifikowany w mechanice jazdy, ale także w zrozumieniu bezpieczeństwa na drodze i etykiety.
							</Text>
						</Box>
						<Box min-width="100px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px" min-height="80px">
							<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								Nasza historia
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Akademia Crenzol nie ma jednego założyciela - to historia napisana przez każdego ucznia, który kiedykolwiek przekręcił kluczyk w jednej z naszych stacyjek. Każdy pomyślnie zdany test, każdy pokonany strach i wszystkie kamienie milowe osiągnięte przez naszych uczniów składają się na naszą bogatą, ewoluującą narrację.
							</Text>
						</Box>
						<Box min-width="100px" padding="0px 5px 0 5px" margin="0px 0px 30px 0px" min-height="80px">

							<Text margin="0px 0px 40px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
								Nasze zaangażowanie
							</Text>
							<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
								W Akademii Crenzol jesteśmy zaangażowani w tworzenie odpowiedzialnych, wykwalifikowanych kierowców, którzy są gotowi do poruszania się po drogach z pewnością siebie i ostrożnością.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image max-width="340px" src="https://crenzol.com/img/4.jpg" md-margin="0px 0px 20px 0px" />
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="normal 600 26px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
					Dlaczego warto wybrać Crenzol Academy?
				</Text>
				<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
					- Spersonalizowane nauczanie: Nasze podejście do nauczania jest dostosowane do tempa i stylu uczenia się każdego ucznia, dzięki czemu nikt nie pozostaje w tyle.
					<br /><br />
					- Najnowocześniejsze symulatory: Oprócz szkoleń w terenie oferujemy sesje z najnowocześniejszymi symulatorami zapewniającymi kompleksowe doświadczenie edukacyjne.
					<br /><br />
					- Różnorodność kursów: Od początkujących do zaawansowanych, w tym jazdy defensywnej i specjalistycznych szkoleń, zaspokajamy wszystkie poziomy i potrzeby.
					<br /><br />
					- Angażujące środowisko: Nasze przestrzenie do nauki są zaprojektowane tak, aby były przyjazne i bezstresowe, promując optymalną atmosferę do nauki.

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});